<template>
  <div id="app">
    <div class="main-app">
      <ul class="block-align-left frame" v-show="show_result">
        <li v-for="(name, index) in roles" :key="index">
          <span class="role-span"> {{index + 1}} 号</span>
          <span class="role-span"> {{name}} </span>
        </li>
        <li class="role-line" style="align-self: flex-end;color: gray" >
            <span class="role-span" style="margin-right: 30px" @click="calculate">重发</span>
            <span class="role-span" @click="show_result=false">关闭</span>
        </li>
      </ul>
      <div class="frame">
        <div class="title-menu" style="margin-top: 15px;margin-bottom: 25px;">
            <span class="role-span"> 总人数 {{preset.reduce((partialSum, a) => partialSum + a.count, 0)}} </span>
            <span class="role-span" @click="show_presets = !show_presets"> [ 板子 ] </span>
        </div>
        <ul v-show="!show_presets" class="block-align-center">
          <li class="role-line" v-for="(role, index) in preset" :key="index">
            <input v-model="preset[index].name" placeholder="edit" class="role-span" style="width:80px;margin-right: 20px;">
            <span class="role-span" @click="role.count = Math.max(0, role.count-1)"> - </span>
            <span class="role-span"> {{role.count}} </span>
            <span class="role-span" @click="role.count += 1"> + </span>
            <span class="role-span" @click="preset.splice(index, 1)"> x </span>
          </li>
          <li class="role-span" style="margin-top: 25px; align-self: stretch" @click="add_role">
            Add
          </li>
          <li class="role-span" style="margin-top: 25px; align-self: stretch" @click="calculate">
            发牌
          </li>
        </ul>
        <ul v-show="show_presets" class="block-align-left">
          <li v-for="(item_preset, index) in presetsData" :key="index" @click="preset = presetsData[index].preset;show_presets=false;"
              class="preset-title-line" style="cursor: default;display: flex;flex-direction: column ;align-items: flex-start;">
            <span style="padding: 0"> {{ item_preset.name }} </span>
            <span style="padding: 0;margin-left: 20px;color: gray;text-align: left">  {{ item_preset.detail }}  </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {presets} from '@/PresetsData'
export default {
    name: 'App',
    created(){
        document.title = '狼人杀发牌'
    },
    data(){
        return {
            preset: [
                {name: '狼', count: 3},
                {name: '民', count: 4},
                {name: '预言家', count: 1},
                {name: '女巫', count: 1},
                {name: '猎人', count: 1},
                {name: '守卫', count: 1},
                {name: '黑狼王', count: 1}
            ],
            roles: [],
            show_result: false,
            show_presets: false,
            presetsData: presets
        }
    },
    methods: {
        add_role(){
            this.preset.push({
                name: '', count: 1
            })
        },
        calculate(){
            this.roles = []
            console.log(this.preset)
            for(let ri=0;ri<this.preset.length;ri++){
                let role = this.preset[ri]
                console.log(role)
                for(let i=0;i<role.count;i++){
                    this.roles.push(role.name)
                }
            }
            console.log(this.roles)
            this.roles = this.fisher_yates_shuffle(this.roles)
            this.show_result = true
        },
        fisher_yates_shuffle(array){
            let index = -1, length = array.length, lastIndex = length-1;
            while(++index < length) {
                let rand = index + Math.floor(Math.random() * (lastIndex - index + 1))
                let value = array[rand]
                array[rand] = array[index]
                array[index] = value
            }
            return array
        }
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.main-app{
    width: 320px;
    margin-left: auto;
    margin-right: auto;
}
.title-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.block-align-center {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0;
}
.block-align-left {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: left;
    padding: 0;
}
.frame{
    padding: 15px;
    border: 1px solid black;
    border-radius: 15px;
    margin: 10px;
}
.role-line {
    margin-top: 5px;
    margin-bottom: 5px;
}
.role-span {
    margin-left: 5px;
    margin-right: 5px;
    cursor: default;
}
.preset-title-line {
    padding: 5px;
    margin: 5px 1px;
}
.preset-title-line:hover {
    border: 1px solid gray;
    border-radius: 10px;
    margin: 4px 0;
    background-color: rgb(230, 230, 230);
}
</style>
