export let presets = [
    {
        name: '12 预女猎守 狼枪',
        detail: '预 女 猎 守 民x4 普狼x3 狼枪',
        preset: [
            {name: '狼', count: 3},
            {name: '民', count: 4},
            {name: '预言家', count: 1},
            {name: '女巫', count: 1},
            {name: '猎人', count: 1},
            {name: '守卫', count: 1},
            {name: '黑狼王', count: 1}
        ]
    },
    {
        name: '12 预女猎白',
        detail: '预 女 猎 白 民x4 普狼x4',
        preset: [
            {name: '狼', count: 4},
            {name: '民', count: 4},
            {name: '预言家', count: 1},
            {name: '女巫', count: 1},
            {name: '猎人', count: 1},
            {name: '白痴', count: 1},
        ]
    },
    {
        name: '14 盗丘 预女猎白守',
        detail: '预 女 猎 白 守 民x5 普狼x4 丘比特 盗贼',
        preset: [
            {name: '狼', count: 4},
            {name: '预言家', count: 1},
            {name: '女巫', count: 1},
            {name: '猎人', count: 1},
            {name: '守卫', count: 1},
            {name: '白痴', count: 1},
            {name: '民', count: 5},
            {name: '丘比特', count: 1},
            {name: '盗贼', count: 1},
        ]
    },
    {
        name: '10 奇迹商人',
        detail: '预 女 奇 民x4 普狼x2 狼枪',
        preset: [
            {name: '狼', count: 2},
            {name: '黑狼王', count: 1},
            {name: '预言家', count: 1},
            {name: '女巫', count: 1},
            {name: '奇迹商人', count: 1},
            {name: '民', count: 4},
        ]
    },
    {
        name: '12 奇迹商人',
        detail: '预 女 守 奇 民x4 普狼x3 狼枪',
        preset: [
            {name: '狼', count: 3},
            {name: '黑狼王', count: 1},
            {name: '预言家', count: 1},
            {name: '女巫', count: 1},
            {name: '守卫', count: 1},
            {name: '奇迹商人', count: 1},
            {name: '民', count: 4},
        ]
    },
    {
        name: '12 骑士狼美',
        detail: '预 女 守 骑 民x4 普狼x3 狼美',
        preset: [
            {name: '狼', count: 3},
            {name: '美人', count: 1},
            {name: '预言家', count: 1},
            {name: '女巫', count: 1},
            {name: '猎人', count: 1},
            {name: '骑士', count: 1},
            {name: '民', count: 4},
        ]
    },
    {
        name: '12 时波之乱 (白昼永夜)',
        detail: '白昼 永夜 预 女 守 民x3 普狼x3',
        preset: [
            {name: '狼', count: 3},
            {name: '寂夜导师', count: 1},
            {name: '预言家', count: 1},
            {name: '女巫', count: 1},
            {name: '守卫', count: 1},
            {name: '白昼学者', count: 1},
            {name: '民', count: 4},
        ]
    },
    {
        name: '12 无目之夜',
        detail: '狼 隐狼 石像鬼 狼鸦之爪 预 女 猎 摄梦人 民x4',
        preset: [
            {name: '普狼', count: 1},
            {name: '隐狼', count: 1},
            {name: '石像鬼', count: 1},
            {name: '狼鸦之爪', count: 1},
            {name: '预言家', count: 1},
            {name: '女巫', count: 1},
            {name: '猎人', count: 1},
            {name: '摄梦人', count: 1},
            {name: '民', count: 4},
        ]
    },
    {
        name: '12 迷雾鸦影 (炼金魔女)',
        detail: '炼金魔女 狼鸦之爪 预 女 白 摄梦人 狼x3 民x4',
        preset: [
            {name: '狼', count: 3},
            {name: '狼鸦之爪', count: 1},
            {name: '预言家', count: 1},
            {name: '炼金魔女', count: 1},
            {name: '白痴', count: 1},
            {name: '摄梦人', count: 1},
            {name: '民', count: 4},
        ]
    },
    {
        name: '12 永序之轮',
        detail: '蚀时狼妃 定序王子 预 女 守 狼x3 民x4',
        preset: [
            {name: '狼', count: 3},
            {name: '蚀时狼妃', count: 1},
            {name: '预言家', count: 1},
            {name: '女巫', count: 1},
            {name: '守卫', count: 1},
            {name: '定序王子', count: 1},
            {name: '民', count: 4},
        ]
    },
    {
        name: 'Jungle 血染鹅鸭杀 镇民',
        detail: '19选13镇民/鹅',
        preset: [
            {name: '冒险家', count: 1},
            {name: '侦探', count: 1},
            {name: '星界行者', count: 1},
            {name: '工程师', count: 1},
            {name: '锁匠', count: 1},
            {name: '通灵者', count: 1},
            {name: '殡仪员', count: 1},
            {name: '保镖', count: 1},
            {name: '警长', count: 1},
            {name: '跟踪者', count: 1},
            {name: '追踪者', count: 1},
            {name: '正义使者', count: 1},
            {name: '观鸟者', count: 1},
            {name: '政治家', count: 1},
            {name: '复仇者', count: 1},
            {name: '肉汁', count: 1},
            {name: '网红', count: 1},
            {name: '加拿大鹅', count: 1},
            {name: '模仿者', count: 1},
     ]}, {
        name: 'Jungle 血染鹅鸭杀 外来者',
        detail: '6选4外来者/中立',
        preset: [
            {name: '鸽子', count: 1},
            {name: '鹈鹕', count: 1},
            {name: '秃鹫', count: 1},
            {name: '猎鹰', count: 1},
            {name: '呆呆鸟', count: 1},
            {name: '决斗呆呆鸟', count: 1},
     ]},{
        name: 'Jungle 血染鹅鸭杀 爪牙',
        detail: '8选4爪牙',
        preset: [
            {name: '间谍', count: 1},
            {name: '静语者', count: 1},
            {name: '食鸟鸭', count: 1},
            {name: '爆炸王', count: 1},
            {name: '刺客', count: 1},
            {name: '雇佣杀手', count: 1},
            {name: '专业杀手', count: 1},
            {name: '告密者', count: 1},
     ]},{
        name: 'Jungle 血染鹅鸭杀 恶魔',
        detail: '6选4恶魔',
        preset: [
            {name: '连环杀手', count: 1},
            {name: '丧葬者', count: 1},
            {name: '忍者', count: 1},
            {name: '术士', count: 1},
            {name: '隐行者', count: 1},
            {name: '超能力者', count: 1},
     ]}
]
